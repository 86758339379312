import React from 'react';
import { API } from 'aws-amplify';
import { getAllSubmittedActionsForUser } from '../graphql/queries';
import { getSimpleUserActionFromResponse } from '../utils/get-simple-user-action-from-response';
import { useGetCurrentUserProfile } from './use-get-current-user-profile.hook';

export const useGetUserAllActions = () => {
  const { userProfile } = useGetCurrentUserProfile();
  const [loading, setLoading] = React.useState(false);
  const [userSubmittedActions, setUserSubmittedActions] = React.useState([]);

  const fetchAllUserSubmittedActions = React.useCallback(async () => {
    if (!userProfile?.id) {
      console.warn('User ID is not defined.');
      return;
    }

    setLoading(true);

    try {
      const res = await API.graphql({
        query: getAllSubmittedActionsForUser,
        variables: { user_id: userProfile.id },
      });

      const actions = res?.data?.getAllSubmittedActionsForUser || [];
      const simplifiedActions = actions.map(getSimpleUserActionFromResponse);

      setUserSubmittedActions(simplifiedActions);
    } catch (err) {
      console.error('Error fetching actions:', err);
    } finally {
      setLoading(false);
    }
  }, [userProfile?.id]);

  React.useEffect(() => {
    if (userProfile?.id && userSubmittedActions.length === 0 && !loading) {
      fetchAllUserSubmittedActions();
    }
  }, [fetchAllUserSubmittedActions, userProfile?.id, userSubmittedActions, loading]);

  return {
    userSubmittedActions,
    loading,
    fetchAllUserSubmittedActions
  };
};
