const translations = {
  changeLanguage: 'Langue: ',
  welcomeName: 'Bienvenue {0} !',
  signIn: 'Se Connecter',
  signInGoogle: 'Se connecter avec Google',
  signInFacebook: 'Se connecter avec Facebook',
  email: 'Email',
  password: 'Mot de passe',
  forgotPassword: 'Mot de passe oublié?',
  createAccount: 'Créer un compte',
  verifyAccount: 'Vérifier le compte',
  passwordReset: 'Réinitialisation du mot de passe',
  setNewPassword: 'Définir un nouveau mot de passe',
  createNewPassword: 'Créer un nouveau mot de passe',
  welcome: 'Bienvenue',
  back: 'Retour',
  or: 'Ou',
  incorrectLogin: "Nom d'utilisateur ou mot de passe incorrect.",
  name: 'Nom',
  username: "Nom d'utilisateur",
  confirmPassword: 'Confirmer le mot de passe',
  passwordSettings: 'Votre mot de passe doit avoir les éléments suivants :',
  oneUppercase: 'Au moins une lettre majuscule',
  oneLowercase: 'Au moins une lettre minuscule',
  oneDigit: 'Au moins un chiffre',
  oneSpecialCharacter: 'Au moins un caractère spécial',
  more8Characters: 'Doit comporter plus de 8 caractères',
  less16Characters: 'Doit comporter moins de 16 caractères',
  fillAllFields: 'Veuillez remplir tous les champs.',
  validEmail: 'Veuillez entrer une adresse email valide.',
  validEmailAccount:
    'Veuillez entrer une adresse email valide ou créer un compte',
  here: 'ici',
  passwordNotMatch: 'Les mots de passe ne correspondent pas',
  signUp: "S'inscrire",
  enterEmailAddress:
    'Entrez votre adresse email et nous vous enverrons un code pour vous aider à réinitialiser votre mot de passe.',
  sendResetCode: 'Envoyer le code de réinitialisation',
  enterResetCode: 'Entrez le code de réinitialisation',
  checkEmail: 'Veuillez vérifier votre email',
  forResetCodeAndCreatePassword:
    'pour un code de réinitialisation et créer un nouveau mot de passe.',
  enterCorrectResetCode: 'Veuillez entrer le code de réinitialisation correct.',
  reEnterPassword: 'Re-saisir le mot de passe',
  updatePassword: 'Mettre à jour le mot de passe',
  emailAlreadyExists: "Un compte avec l'email fourni existe déjà.",
  checkEmailForConfirmationCode:
    'Veuillez vérifier votre email pour un code de confirmation. Cela peut prendre plusieurs minutes.',
  invalidVerificationCode:
    'Code de vérification fourni invalide, veuillez réessayer.',
  newVerificationCodeSentSuccesfully:
    'Nouveau code de vérification envoyé avec succès.',
  enterConfirmationCode: 'Entrez votre code de confirmation.',
  didNotReceiveCode: "N'avez-vous pas reçu votre code de vérification?",
  resendCode: 'Renvoyer le code',
  replaceTemporaryPassword:
    'Veuillez remplacer votre mot de passe temporaire par un nouveau mot de passe pour',
  setPassword: 'Définir le mot de passe',
  alertNewActionTitle: 'Nouvelles actions nécessitant une validation',
  alertNewActionText: 'Vous avez {0} actions à valider !',
  startValidating: 'Commencer la validation',
  alertActionsPendingTitle: '{0} actions en attente de validation',
  alertActionsPendingText:
    'L’impact de vos actions en attente est de {0} de CO2 économisés',
  recentProgress: 'Progrès récents',
  logANewAction: 'Enregistrer une nouvelle action',
  co2SavedWeek: 'CO2 économisé cette semaine',
  collectiveImpact: 'Impact collectif',
  myGroups: 'Mes groupes',
  myActions: 'Mes actions',
  createNewGroup: 'Créer un nouveau groupe',
  globalGroups: 'Groupes globaux',
  globalUsers: 'Utilisateurs globaux',
  totalCO2Saved: 'CO2 total économisé',
  weeklyCO2Saved: 'CO2 économisés hebdomadaires',
  leaderboardDisplaying: 'Tableau affichant les {tab} premiers classés par ',
  rank: 'Rang',
  totalCO2: 'Total CO2',
  totalPoints: 'Total de points',
  membersCount: 'Nombre de membres',
  weeklyCO2: 'CO2 hebdomadaire',
  weeklyPoints: 'Points hebdomadaires',
  totalCO2Colon: 'Total CO2 :',
  totalPointsColon: 'Total de points :',
  weeklyCO2Colon: 'CO2 hebdomadaire :',
  weeklyPointsColon: 'Points hebdomadaires :',
  leaderboards: 'Classements',
  noMyGroups:
    'Aucun groupe à afficher. Créez ou rejoignez un groupe pour commencer !',
  viewGroupStats: 'Voir les statistiques du groupe',
  myContributions: 'Mes contributions',
  myContribution: 'Ma contribution',
  allOtherMembers: 'Tous les autres membres',
  joinGroup: 'Rejoindre le groupe',
  read: 'Lire',
  more: 'Plus',
  less: 'Moins',
  membersColon: 'Membres :',
  public: 'Public',
  private: 'Privé',
  leaveGroup: 'Quitter le groupe',
  currentPlace: 'Lieu actuel',
  labelRowsPerPage: 'Lignes par page :',
  of: 'de',
  groupInfo: 'Infos du groupe',
  memberActions: 'Actions des membres',
  groupMembers: 'Membres du groupe',
  addMembers: 'Ajouter des membres',
  editGroupInfo: 'Modifier les infos du groupe',
  about: 'À propos',
  groupOwners: 'Propriétaires du groupe',
  view: 'Voir',
  noActions: "Il n'y a actuellement aucune action à afficher.",
  co2SavedColon: 'CO2 Économisé :',
  totalPointsEarnedColon: 'Points Totals Acquis :',
  unapprove: 'Désapprouver',
  promoteUserToOwner: 'Promouvoir un utilisateur comme propriétaire du groupe',
  demoteUserToMember: 'Rétrograder un utilisateur comme membre du groupe',
  removeUserFromGroup: 'Retirer l’utilisateur du groupe',
  roleColon: 'Rôle:',
  yourRoleColon: 'Votre rôle:',
  viewUserProfile: 'Voir le profil de l’utilisateur',
  success: 'Succès !',
  warning: 'Attention !',
  successUserNowOwner: '{0} est maintenant un propriétaire de groupe',
  successUserNowMember: '{0} est maintenant un membre du groupe',
  successUserNowRemoved: '{0} a été retiré du groupe',
  demotionErrorTitle: 'Erreur de rétrogradation',
  demotionErrorText:
    'Les groupes doivent avoir au moins 1 propriétaire de groupe',
  addMembersDescription:
    'Ajouter des utilisateurs à ce groupe en leur envoyant votre lien de groupe',
  addMembersDescriptionPassword:
    'Ajouter des utilisateurs à ce groupe en leur envoyant votre lien de groupe et votre mot de passe',
  groupLinkLabel: 'Votre lien de groupe est :',
  groupPasswordLabel: 'Votre mot de passe de groupe est :',
  linkCopied: 'Lien copié !',
  passwordCopied: 'Mot de passe copié !',
  editGroupEmptyError: 'Nom de groupe vide',
  editGroupTakenError: 'Le nom de groupe est déjà pris',
  editGroupPasswordError: 'Mot de passe vide',
  groupUploadIcon: 'Télécharger l’icône de groupe',
  groupNameEmptyError: 'Le champ doit être rempli',
  groupNameTakenError: 'Un groupe existe déjà avec le nom donné',
  description: 'Description',
  groupPrivacy: 'Confidentialité du groupe',
  groupPasswordEmptyError: 'Les groupes privés doivent avoir un mot de passe',
  deleteGroup: 'Supprimer le groupe',
  saveGroupInfo: 'Enregistrer les informations du groupe',
  join: 'Joindre',
  cancel: 'Annuler',
  delete: 'Supprimer',
  leave: 'Quitter',
  groupUpdatedMsg: 'Le groupe a été mis à jour',
  groupDeletedMsg: '{0} a été supprimé !',
  homepageRedirectMsg: "Vous serez redirigé vers la page d'accueil",
  deleteGroupPrompt: 'Supprimer {0}',
  deletePrompAlert: 'Cette action est irréversible',
  logout: 'Déconnexion',
  leaveGroupLastMemberWarning:
    "Ce groupe sera supprimé puisqu'il n'y aura plus de membres.",
  promoteOtherUserBeforeLeavingGroup:
    'Vous devez promouvoir un autre utilisateur comme propriétaire de groupe avant de quitter le groupe.',
  removeGroupOwner: 'Retirer le propriétaire de groupe',
  promoteOtherUserBeforeRemovingUser:
    'Vous devez promouvoir un autre utilisateur en tant que propriétaire du groupe avant de supprimer cet utilisateur.',
  leftGroup: 'Vous avez quitté le groupe !',
  joinGroupAlreadyMember: 'Vous êtes déjà membre de ce groupe !',
  joinGroupTitle: 'Vous avez été invité à rejoindre {0}',
  successRedirectProfilePage:
    'Succès ! Redirection vers la page de profil de {0}',
  joinGroupConfirm: 'Veuillez confirmer en sélectionnant Rejoindre.',
  joinGroupSelectCancel:
    'Sélectionnez Annuler pour être redirigé vers la page du groupe.',
  joinGroupEnterPassword:
    'Pour rejoindre ce groupe, veuillez entrer le mot de passe du groupe et sélectionner Rejoindre.',
  incorrectPassword: 'Mot de passe incorrect',
  logAction: "Enregistrer l'action",
  logActionMobile: "Enregistrer l'action",
  dashboard: 'Tableau de bord',
  home: 'Accueil',
  groups: 'Groupes',
  findGroup: 'Trouver un groupe',
  createGroup: 'Créer un groupe',
  validateActions: 'Valider les actions',
  adminDashboard: "Tableau de bord d'administration",
  myAccount: 'Mon compte',
  logActionTitle: 'Enregistrer une nouvelle action',
  logActionStep1: 'Sélectionner une action',
  logActionStep2: 'Sélectionner des dates',
  logActionStep3: "Fait sur l'action",
  logActionStep4: 'Validation',
  logActionStep5: 'Question bonus',
  logActionStep6: 'CO2 économisé',
  next: 'Suivant',
  previous: 'Précédente',
  chooseDate: 'Choisir une date',
  mustBeNumber: 'La valeur doit être un nombre ou un décimal supérieur à 0',
  imageValidationText:
    "Veuillez télécharger une image liée à l'élément d'action pour la vérification",
  imageValidationDimensions: 'Dimensions minimales 100x100 px',
  imageValidationError: "Ce n'est pas un fichier image",
  imageValidationSelected: 'Image sélectionnée !',
  imageValidationDrop: 'Déposez votre image ici, ou',
  browse: 'Naviguer',
  selectFile: "Sélectionnez un fichier",
  showUsTheProof: "Montrez-nous la preuve !",
  linkHere: ' Naviguer',
  uploadImage: "Télécharger l'image",
  skip: 'Passer',
  actionFactResponse: 'Merci pour la soumission de votre action !',
  actionFactAllFacts: 'Vous avez consulté tous les faits pour cette action',
  bonusQuizChoicesLabel: 'Choix de réponse',
  bonusQuizSubmit: 'Soumettre le quiz',
  bonusQuizSkip: 'Passer le quiz',
  bonsuQuiz: 'Bonus le quiz',
  correct: 'Correct !',
  incorrect: 'Incorrect !',
  finish: 'Terminer',
  tryAgain: 'Réessayer',
  co2SavedScreenState: 'Votre action est en cours de',
  submitted: 'soumission',
  validated: 'validation',
  co2SavedScreenSkip:
    'Appuyez sur "Passer" pour afficher les résultats de validation ultérieurement',
  thankYou: 'Merci !',
  co2SavedScreenValidated: 'Votre action a été validée',
  co2SavedScreenSaved: 'Vous avez économisé {0} g de CO2',
  co2SavedScreenApproval:
    "Votre action est en attente d'approbation de l'administrateur",
  co2SavedScreenImpact:
    "L'impact de votre action est de {0} g de CO2 économisé",
  co2SavedScreenAnother: 'Ajouter une autre action',
  createGroupTitle: 'Créer un nouveau groupe',
  uploadGroupIcon: 'Télécharger une icône de groupe',
  groupName: 'Nom du groupe',
  groupDescription: 'Description du groupe',
  redirectGroupPage: 'Vous serez maintenant redirigé(e) vers la page du groupe',
  nameC: 'Nom :',
  emailC: 'E-mail :',
  editInfo: 'Modifier les informations',
  loggedActions: 'Actions enregistrées',
  awaitingValidation: 'En attente de validation',
  notPassValidation: 'N’a pas passé la validation',
  noValidatedActions: 'Il n’y a aucune action validée à afficher',
  noUnvalidatedActions: 'Il n’y a aucune action non validée à afficher',
  noFailedActions: 'Il n’y a aucune action échouée à afficher',
  editAccountInfoTitle: 'Modifier les informations du compte',
  uploadProfilePhoto: 'Télécharger une photo de profil',
  requiredFieldsMessage: 'Veuillez remplir tous les champs obligatoires',
  saveNameEmailPhoto: 'Enregistrer le nom, l’e-mail, la photo',
  deleteAccount: 'Supprimer le compte',
  deleteAccountWarning: 'Êtes-vous sûr de vouloir supprimer votre compte ? Toutes vos informations seront supprimées et vous serez déconnecté.',
  deleteAccountProgress: 'Suppression de votre compte...',
  deleteGroupsMsg: 'Avant de supprimer votre compte, veuillez attribuer de nouveaux administrateurs aux groupes suivants que vous possédez :',
  changeFilter: 'Change filter',
  changePassword: 'Changer le mot de passe',
  currentFilter: 'Current filter',
  currentPassword: 'Mot de passe actuel',
  currentPasswordMessage: 'Le mot de passe actuel est incorrect',
  newPassword: 'Nouveau mot de passe',
  confirmNewPassword: 'Confirmer le nouveau mot de passe',
  passwordsDontMatch: 'Les mots de passe ne correspondent pas.',
  savePassword: 'Enregistrer le mot de passe',
  toggleCurrentPassword: 'basculer la visibilité du mot de passe actuel',
  toggleNewPassword: 'basculer la visibilité du nouveau mot de passe',
  toggleConfirmNewPassword:
    'basculer la visibilité de la confirmation du nouveau mot de passe',
  findGroupTitle: 'Recherchez un groupe',
  findGroupDescription: 'Entrez le nom de groupe d’un groupe public',
  findGroupErrorSearch:
    'Votre recherche pour « {0} » ne correspond à aucun groupe public',
  findGroupTopGroups:
    'Voici les 5 meilleurs groupes publics auxquels vous pourriez vouloir appartenir',
  searchResults: 'Résultats de la recherche',
  validateActionsUsersWithoutGroupsTab: 'Utilisateurs sans groupes',
  validateActionsAllUnvalidatedActionsTab: 'Toutes les actions non validées',
  searchByC: 'Recherche par :',
  actionName: 'Nom de l’action',
  search: 'Rechercher',
  groupNameSearchEmptySearch:
    'Votre recherche pour « {0} » ne correspond à aucun de vos groupes',
  groupNameSearchNoGroups: 'Vous ne possédez actuellement aucun groupe',
  filteringBy: 'Filtrage par :',
  sortingBy: 'Trier par',
  changeSortingOrder: "Modifier l'ordre de tri",
  groupNameSearchAllFilter: 'Tous mes groupes',
  validateActionsNoActions: 'Il n’y a aucune action à valider',
  actionNameSearchAllFilter: 'Tous les types d’actions',
  actionNameSearchEmptySearch:
    'Votre recherche pour « {0} » ne correspond à aucun nom de type d’action',
  actionNameSearchNoActions: 'Il n’y a actuellement aucun type d’action',
  submittedInC: 'Soumis dans :',
  accept: 'Accepter',
  reject: 'Rejeter',
  clearSearch: '',
  actions: 'Actions',
  totalUsers: 'Utilisateurs Totaux',
  totalGroups: 'Groupes Totaux',
  actionsSubmitted: 'Actions Soumises',
  actionsSubmittedFilter: 'Actions soumises au cours des {0} derniers',
  allTimeStats: 'Statistiques Toutes Périodes',
  totalActionsSubmitted: "Nombre Total d'Actions Soumises",
  actionsFirstAnswerCorrect:
    'Actions Avec la Première Réponse de Quiz Correcte',
  actionsFirstAnswerIncorrect:
    'Actions Avec la Première Réponse de Quiz Incorrecte',
  actionsSubmittedWithImage: 'Actions Soumises Avec Image',
  actionsSubmittedWithoutImage: 'Actions Soumises Sans Image',
  numberActionsSubmittedWithImage: "Nombre d'Actions Soumises Avec Image",
  numberActionsSubmittedWithoutImage: "Nombre d'Actions Soumises Sans Image",
  date: 'Date',
  gramsCO2Saved: 'Grammes CO2 Sauvés',
  applyFilter: 'Appliquer le Filtre',
  createAction: 'Créer une Action',
  manageActions: 'Gérer les Actions',
  manageQuizQuestions: 'Gérer les Questions de Quiz',
  sevenDays: '7 Jours',
  thirtyDays: '30 Jours',
  ninetyDays: '90 Jours',
  oneYear: 'Année',
  co2SavedDuring: 'CO2 Sauvé au cours des {0} derniers',
  userNoteGroupOwner: ' (propriétaire)',
  totalGramsSavedCO2PerDayAllActions: 'Grammes CO2 Sauvés au Total Par Jour Pour Toutes les Actions',
  continue: 'Continuez',
  done: 'Terminer',
  submit: 'Soumettre',
  extraPoints: 'Obtenez des points supplémentaires !',
  logActionButtonAddAnother: 'Ajouter un autre',
  logActionButtonAllDone: 'Tout est fait',
  commit2ActHashtag: '#EngagezVous',
  logActionStepCompleted: 'Terminé',
  logActionStepCurrent: 'Actuel',
  logActionStepOfTotal: 'Étape {0} de {1}',
  logActionItemsErrorAlt: 'Erreur',
  logActionShareSummarySimple:
    'Je vous mets au défi de reproduire mon action en économisant +{0} g de CO2 !',
  shareOnInstagram: 'Partagez sur Instagram',
  shareOnTikTok: 'Partagez sur TikTok',
  copyButtonCopy: 'Copier',
  copyButtonCopied: 'Copié !',
  logActionValidationLoading: 'Validation des détails de l\'action',
  logActionValidationComplete: 'Validation terminée',
  your: 'Votre',
  actionIsSubmitted: 'l\'action est en cours de soumission',
  savingThePlanet: 'Incroyable, vous sauvez la planète !',
  checkAnswer: 'Vérifier la réponse',

  signup: {
    progressLabel: '{0} pour cent',
    stepLabel: 'Étape {0}',
    buttonBack: 'Retour',
    buttonNext: 'Suivant',
    buttonContinue: 'Continuez',
    buttonComplete: 'Terminez',
    buttonConfirm: 'Confirmez',
    buttonSignup: 'S\'inscrire',
    buttonLetsGo: 'Allons-y !',
    buttonLetsTakeAction: 'Passons à l\'action !',
    infoPrimaryHeadingOne: 'Super !',
    infoPrimarySubHeading: 'Commençons',
    onboardStartHeading: 'Avant de commencer à agir...',
    onboardStartSubHeading: 'Nous voulons mieux comprendre votre niveau d\'expérience',
    infoSecondaryHeading: 'Super !',
    infoSecondarySubHeading: 'Commençons',
    avatar: 'Avatar',
    avatarSelectAriaLabel: 'Étiquette de sélection de l\'avatar',
    avatarSelectHeading: 'Choisissez votre avatar',
    avatarSelectSubHeading: 'Choisissez quel animal marin vous souhaitez utiliser dans l\'application !',
    avatarReviewHeading: 'Excellent choix !',
    avatarReviewSubHeading: 'Le {0} sera votre compagnon tout au long de votre parcours',
    subjectExperiencedHeading: 'Quel est votre niveau d\'expérience en matière d\'action climatique ?',
    subjectFactHeading: 'Fantastique ! Saviez-vous que...',
    usageIntendHeading: 'Comment préférez-vous utiliser cette application ?',
    usageInstructHeading: 'Super ! Voici un aperçu :',
    usageInstructSubheading: 'Commit2Act vous permet de suivre l\'impact de vos actions quotidiennes. Vous pouvez rivaliser avec vos camarades de classe, vos groupes scolaires ou d\'autres écoles pour faire du bien. Vous pouvez tester vos connaissances avec des quiz et utiliser la reconnaissance d\'images par IA pour valider vos images d\'impact !',
    actionsSelectHeading: 'Passons à l\'action maintenant',
    actionsSelectSubheading: 'Quelles actions seraient les plus faciles pour vous à intégrer dans votre routine quotidienne :',
    actionsImpactHeading: 'Incroyable !',
    actionsImpactSubheading1a: 'Vos actions pourraient économiser jusqu\'à ',
    actionsImpactSubheading1b: ' de CO2 chaque semaine !',
    actionsImpactSubheading2: 'Si vous agissez avec vos amis et votre communauté, cet impact serait beaucoup, beaucoup plus important !',
    actionsImpactSubheading3: 'De plus, vous pouvez gagner encore plus de points bonus en ajoutant une action chaque jour !',
    actionsIgnoreHeading: 'Y a-t-il des actions que vous ne pouvez pas faire ?',
    actionsIgnoreSubheading: 'Par exemple, si vous vivez dans une communauté sans transports en commun, ou si vous n\'avez pas accès à de l\'eau potable, ces actions peuvent ne pas être possibles. Nous ne vous inciterons pas à prendre des actions que vous ne pouvez pas faire.',
    onboardEndHeading: 'Vous êtes prêt !!!',
    onboardEndSubheading: 'Nous sommes impatients de voir l\'impact que vous allez réaliser !',
    skipOnboardText: 'Pressé de commencer à agir ?',
    skipOnboardLink: 'Sauter pour l\'instant',
    nickname: 'Pseudonyme',
    enterYourNickname: 'Entrez votre \'Pseudonyme',
    ageRange: 'Tranche d\'âge',
    country: 'Pays',
    province: 'Province',
    loadingText: 'Chargement',
  },

  textInput: {
    requiredTextInput: 'Ce champ est requis.',
    invalidEmail: 'Adresse e-mail invalide.',
    togglePasswordVisibility: 'Basculer la visibilité du mot de passe',
  },

  logActionPage: {
    noActionsAvailable: 'Aucune action disponible',
    loadingActions: 'Chargement des actions',
    noActionItemsAvailable: 'Aucun élément d\'action disponible',
    loadingActionItems: 'Chargement des éléments d\'action',
  },

  dashboardPage: {
    statProgress: 'Votre progression stat-astique',
    yourActions: 'Vos actions',
    growYourKnowledge: 'Développez vos connaissances et votre compréhension',
    leaderboard: 'Le classement',
    yourImpact: 'Votre impact',
    actionsThisWeek: 'Actions cette semaine',
    weeklyActions: '{0} Actions',
    awaitingValidation: 'En attente de validation',
    actionsBreakdown: 'Répartition de mes actions',
    actionsTaken: 'Actions réalisées',
    weeklyCO2: 'CO2 hebdomadaire',
    totalCO2Saved: 'CO2 total économisé',
    currentStreak: 'Série en cours',
    actionsTakenColon: 'Actions réalisées :',
    pointsEarnedColon: 'Points gagnés :',
    totalPointsColon: 'Total des points :',
    logNow: 'Enregistrer maintenant',
    emptyAction1: 'Vous avez fait ',
    emptyAction2: ' actions. Besoin d\'inspiration pour ',
    emptyAction3: 'comment réaliser cette action',
    co2Saved: '{0} économisé',
    thisWeek: 'cette semaine',
    fromLastWeek: 'par rapport à la semaine dernière',
  },
};

export default translations;
