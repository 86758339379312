import { API } from 'aws-amplify';
import { useParams } from 'react-router-dom';
import { getActionBySlug } from '../graphql/queries';
import { useGetAllActions } from './use-get-all-actions';
import { useCallback, useEffect, useState } from 'react';

export const allActionStyles = {
  0: { color: '#ffffff' },
  1: { color: '#8EDAAC' },
  2: { color: '#E661AE' },
  5: { color: '#8CBD5B' },
  3: { color: '#6FDDDD' },
  11: { color: '#F1A660' },
  12: { color: '#FFD467' },
  13: { color: '#cf8bff' },
};

export const useGetSingleAction = () => {
  const { actionId } = useParams();
  const { allActions } = useGetAllActions();
  
  const [slugId, setSlugId] = useState(null);
  const [action, setAction] = useState(null);

  const getSlug = useCallback(async () => {
    if (actionId && isNaN(Number(actionId))) {
      try {
        const response = await API.graphql({
          query: getActionBySlug,
          variables: { slug: actionId },
        });
        setSlugId(response?.data?.getActionBySlug?.action_id);
      } catch (error) {
        console.error('Error fetching slug:', error);
      }
    }
  }, [actionId]);

  useEffect(() => {
    if (actionId && isNaN(Number(actionId))) {
      getSlug();
    }
  }, [actionId, getSlug]);

  useEffect(() => {
    if (slugId || actionId) {
      const foundAction = allActions.find(({ id }) => {
        const currentId = slugId ?? actionId;
        return id.toString() === currentId.toString();
      });
      setAction(foundAction);
    }
  }, [slugId, actionId, allActions]);

  const actionStyles = allActionStyles[slugId ?? actionId] || allActionStyles[0];

  return {
    slugId,
    action,
    actionStyles,
  };
};
