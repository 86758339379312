import { useState } from 'react';
import { API, Storage } from 'aws-amplify';
import { useOutletContext, useParams } from 'react-router-dom';
import { useGetCurrentUserProfile } from './use-get-current-user-profile.hook';
import {
  createSubmittedAction,
  createSubmittedActionItems,
} from '../graphql/mutations';
import { useGetItemsForAction } from './use-get-items-for-action';
import { useGetUserAllActions } from './use-get-user-all-actions';
import { ACTION_POINTS_VALUES } from '../constants/action-points-values';

import awsconfig from '../aws-exports';

const bucketName = awsconfig.aws_user_files_s3_bucket;

export const useCreateUserAction = () => {
  const [loading, setLoading] = useState();

  const { actionId } = useParams();
  const { userProfile } = useGetCurrentUserProfile();
  const { userSubmittedActions, fetchAllUserSubmittedActions } = useGetUserAllActions();
  const { userActionValues, setUserActionValues } = useOutletContext();
  const { actionItems, hasNoItems } = useGetItemsForAction();
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);


  const getActionItemValues = () => {
    if (hasNoItems) return [];
    return actionItems.map((item) => ({
      item_name: item.name,
      input_value:
        userActionValues?.actionItems.find(({ id }) => id === item.id)?.value ||
        '0',
    }));
  };

  const getActionItemsSum = () => {
    let co2Saved = 0;
    let pointsEarned = ACTION_POINTS_VALUES.ACTION;

    if (actionItems.length > 0) {
      actionItems.forEach((item) => {
        const userValue =
          +userActionValues?.actionItems.find(({ id }) => id === item.id)
            ?.value || 0;

        co2Saved += userValue * item.co2Saved;
        pointsEarned +=
          userValue *
          (item.co2Saved !== 0
            ? item.co2Saved / 100
            : ACTION_POINTS_VALUES.ACTION_ITEM);
      });
    }

    return {
      co2Saved,
      pointsEarned: Math.ceil(pointsEarned),
    };
  };

  const createUserAction = async (onSuccess = () => {}) => {
    setLoading(true);

    const actionItemValues = getActionItemValues();
    const actionItemsSum = getActionItemsSum();
    const todayActions = userSubmittedActions.filter(action => action.timeSubmitted && new Date(action.timeSubmitted).toDateString() === new Date().toDateString());

    try {
      // All of these static values are required to be passed
      // on the initial request along with the actual values
      const variables = {
        action_id: actionId,
        date_of_action: userActionValues?.date,
        first_quiz_answer_correct: false,
        g_co2_saved: actionItemsSum?.co2Saved,
        is_validated: todayActions.length < 4 && actionItemsSum?.pointsEarned <= 100 ? true : false,
        points_earned: actionItemsSum?.pointsEarned,
        quiz_answered: false,
        user_id: userProfile?.id,
      };
      const response = await API.graphql({
        query: createSubmittedAction,
        variables,
      });

      const submittedAction = await response.data.createSubmittedAction;
      const userActionId = submittedAction?.sa_id;

      setUserActionValues({
        ...userActionValues,
        pointsEarned: actionItemsSum?.pointsEarned,
        userActionId,
      });

      if (actionItemValues.length > 0) {
        try {
          await API.graphql({
            query: createSubmittedActionItems,
            variables: {
              sa_id: userActionId,
              submitted_action_items: actionItemValues,
            },
          });
        } catch (e) {
          console.log('Error:', e);
        }
      }

      if (userActionValues?.image) {
        let imageType = userActionValues?.image.type;
        let imageKey = 'validation/input/'.concat(
          userActionId,
          '.',
          imageType.split('/')[1]
        );

        await Storage.put(imageKey, userActionValues?.image, {
          contentType: imageType,
        });

        const imageUrl = `${bucketName}/${imageKey}`;
        setSelectedImageUrl(imageUrl);
        console.log('Image URL: ', imageUrl);

      }

      fetchAllUserSubmittedActions();
      setLoading(false);
      onSuccess();
    } catch (e) {
      setLoading(false);
      // This error state will need to be rendered in the UI!
      console.log('Error: ', e);
    }
  };

  return {
    createUserAction,
    loading,
    selectedImageUrl,
  };
};
